import React from 'react'
import './styles.scss'
import Title from '../title'
import { reviews } from '../../data'
import { Container } from 'react-bootstrap'
import shuffledArray from '../../hooks/shuffledArray'
import Stars from '../stars'

const Reviews = () => {
    return (
        <div className='reviewsWrapper'>
            <Title title="Reviews" parent={true} />

            <Container className='allReviewsContainer'>
                {
                    shuffledArray(reviews).map((review) => (
                        <div>
                            <div className='reviewContainer'>
                                <div className='reviewHeader'>
                                    <img src={review.avatar} className='reviewHeaderAvatar' alt={review.avatar} />
                                    <h5 className='reviewHeaderName'>{review.name}</h5>
                                </div>
                                <div className='reviewBody'>
                                    <div>
                                        <Stars />
                                    </div>
                                    <span className='reviewBodyDescription'>{review.description}</span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </Container>
        </div>
    )
}

export default Reviews