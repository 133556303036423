import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './styles.scss';
import { useNavigate } from "react-router-dom";
import Marquee from 'react-fast-marquee';
import { CgDanger } from "react-icons/cg";
import welcomeImage from '../../assets/images/categories/welcomeImage.png'

export default function CustomModal(props) {

  const navigate = useNavigate();

  const SignUpNavigate = () => {
    navigate("/signup");
    props.setModalShow(false)
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='modalWrapper'
    >
      <div className='welcomeImageContainer'>
        <img alt="welcomeImage" src={welcomeImage} width="80%" />
      </div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='modalTitle'>
          Welcome to Bulletaces
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modalFooterContainer'>
        <p className='modalParagraph'>
          Sign up now and get $2000 AUD free credits
        </p>
        <Marquee className='modalParagraph2'>
          <p style={{ margin: 0 }}>
            <CgDanger color='ff0800' size={30} /> No credit card needed for Registration <CgDanger color='ff0800' size={30} />
          </p>
        </Marquee>

      </Modal.Body>
      <Modal.Footer>
        <Button className='signupButton2' onClick={() => SignUpNavigate()}>
          Sign up
        </Button>
      </Modal.Footer>
    </Modal >
  );
}