import React, { useState } from 'react'
import './styles.scss'
import Cookies from 'universal-cookie'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { login } from '../../api';


const SigninFields = () => {
    const [message, setMessage] = useState()
    const cookie = new Cookies()
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: () => {
            const username = formik.values.username
            const password = formik.values.password;

            const func = login(username, password)
            if (username === "" && password === "") {
                navigate('/signin');
            } else {
                func.then(async (response) => {
                    if (response.success) {
                        cookie.set('token', response.token, { path: '/' })
                        cookie.set('firstname', response.data.firstName, { path: '/' })
                        cookie.set('lastname', response.data.lastName, { path: '/' })
                        cookie.set('username', response.data.username, { path: '/' })
                        cookie.set('id', response.data._id, { path: '/' })
                        navigate('/');
                    } else {
                        setMessage('user not found')
                    }
                })
            }

        }
    })


    return (
        <>
            <p className='signInMessage'>
                {message}
            </p>
            <Form onSubmit={formik.handleSubmit}>
                <Row xxl={12} xl={12} lg={12}>
                    <Col xxl={12} xl={12} lg={12}>
                        <Form.Group className="mb-3" id="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                className='input'
                                type="text"
                                placeholder="Enter username"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.username || ''}
                                name="username"
                                id="username"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row xxl={12} xl={12} lg={12}>
                    <Col xxl={12} xl={12} lg={12}>
                        <Form.Group className="mb-3" id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                className='input'
                                type="password"
                                placeholder="Enter password"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.password || ''}
                                name="password"
                                id="password"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row style={{ padding: '0px 16px' }}>
                    <Button type="submit" className='signInSubmitButton'>
                        Sign In
                    </Button>
                </Row>
            </Form>
        </>
    )
}

export default SigninFields