import React from 'react'
import './styles.scss'
import Title from '../title'
import { Col, Container, Row } from 'react-bootstrap'

const GamesIntroduction = () => {
    return (
        <div className='gamesIntroductionWrapper'>
            <Container>
                <Row>
                    <Title title="Exploring the World of Online Casinos" parent={false} />
                    <Col xxl={8}>
                        <span className='gamesIntroductionText mb-4'>
                            In the digital age, the world of entertainment has witnessed a significant transformation,
                            and one of the most exciting developments is the rise of online casinos. Online casinos offer
                            a thrilling and convenient way for players to enjoy the excitement of gambling without leaving
                            the comfort of their own homes. This modern gaming phenomenon has captivated millions of players
                            worldwide, and for good reason.
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Title title="Endless Variety of Games:" capitalize={true} parent={false} />
                    <Col xxl={8}>
                        <span className='gamesIntroductionText mb-4'>
                            Online casinos are a treasure trove of gaming options.
                            From classic table games like blackjack, poker, and roulette
                            to a vast array of slot machines, there's something to suit
                            every player's taste. Additionally, many online casinos
                            regularly introduce new and innovative games to keep the
                            experience fresh and exciting.
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Title title="Convenience and Accessibility:" capitalize={true} parent={false} />
                    <Col xxl={8}>
                        <span className='gamesIntroductionText mb-4'>
                            One of the most appealing aspects of online casinos is their
                            convenience. Players can access their favorite games at any
                            time and from virtually anywhere with an internet connection.
                            Whether you prefer gaming on your computer, smartphone, or
                            tablet, these platforms provide access to a world of entertainment
                            24/7.
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Title title="Bonuses and Promotions:" capitalize={true} parent={false} />
                    <Col xxl={8}>
                        <span className='gamesIntroductionText mb-4'>
                            Online casinos are renowned for their enticing bonuses and promotions.
                            New players are often greeted with generous welcome bonuses, while loyal
                            players can enjoy ongoing rewards such as free spins, cashback,
                            and loyalty programs. These incentives can significantly boost your gaming
                            experience and increase your chances of winning.
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Title title="Safe and Secure Environment:" capitalize={true} parent={false} />
                    <Col xxl={8}>
                        <span className='gamesIntroductionText mb-4'>
                            Reputable online casinos prioritize the safety and security of their players.
                            They employ advanced encryption technology to protect personal and financial
                            information, ensuring a secure gaming environment. Licensed and regulated casinos
                            adhere to strict guidelines, giving players peace of mind while they play.
                        </span>
                    </Col>
                </Row>
            </Container>


        </div>
    )
}

export default GamesIntroduction