import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { games } from '../../data';
import './styles.scss'
import shuffledArray from '../../hooks/shuffledArray';
import { AiOutlinePlayCircle } from 'react-icons/ai'
import { MdEmojiEvents } from 'react-icons/md'
import Title from '../title';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';


const Games = ({ selectedCategoryId }) => {
    const cookies = new Cookies();

    const filteredGames = selectedCategoryId
        ? games.filter((game) => game.catId === selectedCategoryId)
        : games;

    return (
        <Container>
            <Row>
                <Title title="Games" />
            </Row>
            <Row className='gamesWrapper'>
                {shuffledArray(filteredGames).map((game) => (
                    <Col xxl={2} xl={2} lg={2} md={3} sm={3} xs={6} key={game.name} className="gameItemContainer mb-3">
                        <div className='gameItem'>
                            <div className='gameItemImageContainer'>
                                <img src={game.image} className='gameItemImage' alt={game.name} />
                                <div className="gameItemOverlay">
                                    <Link to={!cookies.get("token") && "/signup"}>
                                        <Button className="gameItemPlayButton">
                                            <AiOutlinePlayCircle size={25} color="000" style={{ marginRight: 4 }} />
                                            Play
                                        </Button>
                                    </Link>
                                    <Link to={!cookies.get("token") && "/signup"}>
                                        <Button className="gameItemDemoButton">
                                            <MdEmojiEvents size={25} color="fff" style={{ marginRight: 4 }} />
                                            Demo
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                            <span className='gameItemName'>{game.name}</span>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}
export default Games