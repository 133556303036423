import React, { useState } from 'react'
import SlideShow from '../../components/slideshow'
import Categories from '../../components/categories'
import Games from '../../components/games';
import GamesIntroduction from '../../components/gamesIntroduction';
import Reviews from '../../components/reviews';

const HomePage = () => {

    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    return (
        <>
            <SlideShow />
            <Categories setSelectedCategoryId={setSelectedCategoryId} selectedCategoryId={selectedCategoryId} />
            <Games selectedCategoryId={selectedCategoryId} />
            <GamesIntroduction />
            <Reviews />
        </>
    )
}

export default HomePage