import React, { useState } from 'react'
import './styles.scss'
import { useFormik } from 'formik';
import { register } from '../../api';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { Button, Col, Form, Row } from 'react-bootstrap';

function SignupFields({ setErrorMessage }) {
    const [date, setDate] = useState("");
    const [phoneValue, setPhoneValue] = useState()

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            username: '',
            confirmPassword: '',
            password: '',
            birthdate: date,

        },
        onSubmit: () => {

            const data = {
                firstName: formik.values.firstname,
                lastName: formik.values.lastname,
                email: formik.values.email,
                username: formik.values.username,
                password: formik.values.password,
                birthdate: date,
                phone: phoneValue,
            }

            if (formik.values.password === formik.values.confirmPassword) {
                const func = register(data)
                func.then(async (response) => {
                    if (response.success) {
                        setErrorMessage('Account successfully created')
                        window.location.reload()
                    } else {
                        setErrorMessage('User already token')
                    }
                })

            } else {
                setErrorMessage('Incorrect Password')
            }


        }
    })
    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <Row xxl={12} xl={12} lg={12}>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Form.Group className="mb-2" id="formBasicFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter First Name"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.firstname || ''}
                                name="firstname"
                                id="firstname"
                            />
                        </Form.Group>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Form.Group className="mb-2" id="formBasicLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter Last Name"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.lastname || ''}
                                name="lastname"
                                id="lastname"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row xxl={12} xl={12} lg={12}>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Form.Group className="mb-2" id="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                className='input'
                                required
                                type="text"
                                placeholder="Enter username"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.username || ''}
                                name="username"
                                id="username"
                            />
                        </Form.Group>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Form.Group className="mb-2" id="password">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                className='input'
                                required
                                type="email"
                                placeholder="Enter email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.email || ''}
                                name="email"
                                id="email"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row xxl={12} xl={12} lg={12}>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Form.Group className="mb-2" id="email">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                className='input'
                                required
                                type="password"
                                placeholder="Enter password"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.password || ''}
                                name="password"
                                id="password"
                            />
                        </Form.Group>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Form.Group className="mb-2" id="email">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                className='input'
                                required
                                type="password"
                                placeholder="Enter password"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.confirmPassword || ''}
                                name="confirmPassword"
                                id="confirmPassword"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row xxl={12} xl={12} lg={12}>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Form.Group className="mb-2" id="formBasicPhone" >
                            <Form.Label>Phone Number</Form.Label>
                            <PhoneInput
                                required
                                defaultCountry="US"
                                className='phoneNumberInput'
                                placeholder="Enter phone number"
                                value={phoneValue}
                                onChange={setPhoneValue}
                                name="phone" />
                        </Form.Group>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Form.Group className="mb-2 birthdateContainer" id="formBasicPhone" >
                            <Form.Label>BirthDate</Form.Label>
                            <DatePicker
                                className='date'
                                selected={date}
                                name="date"
                                onChange={(date) => setDate(date)}
                                value={date}
                                placeholderText="Select a Date"
                            />
                        </Form.Group>
                    </Col>
                </Row>


                <Row style={{ padding: '0px 16px' }}>
                    <Button type="submit" className='submitButton'>
                        Sign up
                    </Button>
                </Row>

            </Form>
        </>
    )
}

export default SignupFields