import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/grid'
import 'swiper/css/pagination';
import './styles.scss';
import { Grid, Autoplay } from 'swiper/modules';
import { promotions } from '../../data';
import { Button } from 'react-bootstrap';
import Title from '../title';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';


const SlideShow = () => {
    const cookies = new Cookies();
    const calculateSlidesPerView = () => {
        if (window.innerWidth >= 1200) {
            return 3;
        } else if (window.innerWidth >= 768) {
            return 2;
        } else {
            return 1;
        }
    };
    const [slidesPerView, setSlidesPerView] = useState(calculateSlidesPerView());

    useEffect(() => {
        const handleResize = () => {
            setSlidesPerView(calculateSlidesPerView());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    return (
        <>
            <Title title="Promotions" parent={true} />
            <Swiper
                slidesPerView={slidesPerView}
                grid={{
                    rows: 2,
                }}
                loop={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                spaceBetween={30}

                modules={[Grid, Autoplay]}
                className="mySwiper"
            >
                {
                    promotions?.map(promotion => (
                        <SwiperSlide key={promotion.id}>
                            <div className='promotionImageContainer'>
                                <img src={promotion.image} className='promotionImage' alt="promotion" />
                                <div className='promotionImageOverlay'>
                                    <Link to={!cookies.get("token") && "/signup"}>
                                        <Button className='promotionImageButton'>
                                            See Details
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }

            </Swiper>
        </>
    )
}

export default SlideShow