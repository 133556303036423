import React from 'react'
import './styles.scss'

const Title = ({ title, capitalize, parent }) => {
    return (
        <div className='titleContainer mb-3' style={{ textTransform: capitalize ? "capitalize" : "uppercase" }}>
            <h4 className={`${parent ? "parentTitle" : "title"}`}>
                {title}
            </h4>
        </div >
    )
}

export default Title