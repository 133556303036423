import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './styles.scss'
import { navLinks } from '../../data';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/logo/bulletaces-logo.png';

const NavBar = () => {
    const cookie = new Cookies();
    const navigate = useNavigate();


    const logOutUserMenu = () => {
        cookie.remove('token');
        window.location.reload();
        navigate("/");
    }

    return (
        <Navbar collapseOnSelect expand="lg" className="navbarWrapper">
            <Container>
                <Navbar.Brand href="/"><img src={logo} className='mainLogo' alt='logo' /> </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {
                            navLinks?.map((link) => (
                                <Nav.Link
                                    key={link.id}
                                    href=""
                                    style={{ fontWeight: `${link?.weight}` }}
                                    className='navLinks'
                                >
                                    {link?.link}
                                </Nav.Link>
                            ))
                        }
                    </Nav>
                    <Nav>
                        {

                            cookie.get('token') ?
                                <>
                                    <DropdownButton id="dropdown-basic-button" title={cookie.get('firstname') + " " + cookie.get('lastname')}>
                                        <Dropdown.Item onClick={() => logOutUserMenu()}>LogOut</Dropdown.Item>
                                    </DropdownButton>
                                </>
                                :
                                <>
                                    <Link to="/signin">
                                        <Button className='signinButton'>
                                            Sign in
                                        </Button>
                                    </Link>
                                    <Link to="/signup">
                                        <Button className='signupButton'>
                                            Sign up
                                        </Button>
                                    </Link>
                                </>
                        }


                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    )
}

export default NavBar