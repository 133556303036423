import React from 'react'
import './styles.scss'

const Stars = () => {
    return (
        <>
            <span className="fa fa-star checked fa-lg"></span>
            <span className="fa fa-star checked fa-lg"></span>
            <span className="fa fa-star checked fa-lg"></span>
            <span className="fa fa-star checked fa-lg"></span>
            <span className="fa fa-star checked fa-lg"></span>
        </>
    )
}

export default Stars