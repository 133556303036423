import p1 from './assets/images/promotions/promotion1.png'
import p2 from './assets/images/promotions/promotion2.jpg'
import p3 from './assets/images/promotions/promotion3.png'
import p4 from './assets/images/promotions/promotion4.jpg'
import p5 from './assets/images/promotions/promotion5.jpg'
import p6 from './assets/images/promotions/promotion6.jpg'
import p7 from './assets/images/promotions/promotion7.jpg'
import p8 from './assets/images/promotions/promotion8.jpg'
import p9 from './assets/images/promotions/promotion9.png'
import p10 from './assets/images/promotions/promotion10.png'
import roulette from './assets/images/categories/roulette.png'
import { IoDiamond } from 'react-icons/io5'
import { MdOutlineNewLabel } from 'react-icons/md'
import { TbHexagonNumber7 } from 'react-icons/tb'
import { BsFillDice6Fill } from 'react-icons/bs'
import { GiMoneyStack, GiCardJackDiamonds, GiClubs, GiSpades } from 'react-icons/gi'
import game1 from './assets/images/games/game1.jpg'
import game2 from './assets/images/games/game2.jpg'
import game3 from './assets/images/games/game3.png'
import game4 from './assets/images/games/game4.jpg'
import game5 from './assets/images/games/game5.png'
import game6 from './assets/images/games/game6.jpg'
import game7 from './assets/images/games/game7.jpg'
import game8 from './assets/images/games/game8.jpg'
import game9 from './assets/images/games/game9.jpg'
import game10 from './assets/images/games/game10.jpg'
import game11 from './assets/images/games/game11.png'
import game12 from './assets/images/games/game12.png'
import game13 from './assets/images/games/game13.png'
import game14 from './assets/images/games/game14.jpg'
import game15 from './assets/images/games/game15.png'
import game16 from './assets/images/games/game16.jpg'
import jackpot1 from './assets/images/games/jackpot1.png'
import jackpot2 from './assets/images/games/jackpot2.jpg'
import jackpot3 from './assets/images/games/jackpot3.jpg'
import jackpot4 from './assets/images/games/jackpot4.jpg'
import jackpot6 from './assets/images/games/jackpot6.jpg'
import jackpot7 from './assets/images/games/jackpot7.jpg'
import jackpot8 from './assets/images/games/jackpot8.png'
import jackpot9 from './assets/images/games/jackpot9.png'
import jackpot10 from './assets/images/games/jackpot10.jpg'
import jackpot11 from './assets/images/games/jackpot11.jpg'
import jackpot12 from './assets/images/games/jackpot12.png'
import jackpot13 from './assets/images/games/jackpot13.png'
import jackpot14 from './assets/images/games/jackpot14.jpg'
import jackpot15 from './assets/images/games/jackpot15.jpg'
import jackpot16 from './assets/images/games/jackpot16.png'
import jackpot17 from './assets/images/games/jackpot17.png'
import jackpot18 from './assets/images/games/jackpot18.jpg'
import jackpot19 from './assets/images/games/jackpot19.jpg'
import slots1 from './assets/images/games/slots1.jpg'
import slots2 from './assets/images/games/slots2.jpg'
import slots3 from './assets/images/games/slots3.jpg'
import slots4 from './assets/images/games/slots4.jpg'
import slots5 from './assets/images/games/slots5.png'
import slots6 from './assets/images/games/slots6.jpg'
import slots7 from './assets/images/games/slots7.jpg'
import slots8 from './assets/images/games/slots8.jpg'
import slots9 from './assets/images/games/slots9.jpg'
import slots10 from './assets/images/games/slots10.jpg'
import slots11 from './assets/images/games/slots11.png'
import slots12 from './assets/images/games/slots12.jpg'
import live1 from './assets/images/games/live1.jpg'
import live2 from './assets/images/games/live2.jpg'
import live3 from './assets/images/games/live3.jpg'
import live4 from './assets/images/games/live4.jpg'
import live5 from './assets/images/games/live5.jpg'
import live6 from './assets/images/games/live6.jpg'
import live7 from './assets/images/games/live7.png'
import live8 from './assets/images/games/live8.jpg'
import live9 from './assets/images/games/live9.jpg'
import live10 from './assets/images/games/live10.jpg'
import blackjack1 from './assets/images/games/blackjack1.jpg'
import blackjack2 from './assets/images/games/blackjack2.jpg'
import blackjack3 from './assets/images/games/blackjack3.jpg'
import blackjack4 from './assets/images/games/blackjack4.jpg'
import blackjack5 from './assets/images/games/blackjack5.jpg'
import blackjack6 from './assets/images/games/blackjack6.jpg'
import blackjack7 from './assets/images/games/blackjack7.jpg'
import blackjack8 from './assets/images/games/blackjack8.jpg'
import blackjack9 from './assets/images/games/blackjack9.png'
import blackjack10 from './assets/images/games/blackjack10.png'
import roulette1 from './assets/images/games/roulette1.png'
import roulette2 from './assets/images/games/roulette2.jpg'
import roulette3 from './assets/images/games/roulette3.jpg'
import roulette4 from './assets/images/games/roulette4.jpg'
import roulette5 from './assets/images/games/roulette5.png'
import roulette6 from './assets/images/games/roulette6.png'
import roulette7 from './assets/images/games/roulette7.jpg'
import roulette8 from './assets/images/games/roulette8.jpg'
import roulette9 from './assets/images/games/roulette9.png'
import roulette10 from './assets/images/games/roulette10.jpg'
import roulette11 from './assets/images/games/roulette11.jpg'
import video1 from './assets/images/games/video1.png'
import video2 from './assets/images/games/video2.jpg'
import video3 from './assets/images/games/video3.png'
import video4 from './assets/images/games/video4.png'
import video5 from './assets/images/games/video5.png'
import video6 from './assets/images/games/video6.jpg'
import video7 from './assets/images/games/video7.jpg'
import baccarrat1 from './assets/images/games/baccarrat1.jpg'
import baccarrat2 from './assets/images/games/baccarrat2.jpg'
import avatar1 from './assets/images/avatar/avatar1.jpg'
import avatar2 from './assets/images/avatar/avatar2.jpg'
import avatar3 from './assets/images/avatar/avatar3.jpg'
import avatar4 from './assets/images/avatar/avatar4.jpg'
import avatar5 from './assets/images/avatar/avatar5.jpg'
import avatar6 from './assets/images/avatar/avatar6.jpg'
import avatar7 from './assets/images/avatar/avatar7.jpg'
import avatar8 from './assets/images/avatar/avatar8.jpg'
import avatar9 from './assets/images/avatar/avatar9.jpg'
import avatar10 from './assets/images/avatar/avatar10.jpg'
import avatar11 from './assets/images/avatar/avatar11.jpg'
import avatar12 from './assets/images/avatar/avatar12.jpg'
import avatar13 from './assets/images/avatar/avatar13.jpg'
import avatar14 from './assets/images/avatar/avatar14.jpg'
import avatar15 from './assets/images/avatar/avatar15.jpg'
import avatar16 from './assets/images/avatar/avatar16.jpg'
import avatar17 from './assets/images/avatar/avatar17.jpg'
import avatar18 from './assets/images/avatar/avatar18.jpg'
import avatar19 from './assets/images/avatar/avatar19.jpg'
import avatar20 from './assets/images/avatar/avatar20.jpg'
import avatar21 from './assets/images/avatar/avatar21.jpg'
import avatar22 from './assets/images/avatar/avatar22.jpg'
import avatar23 from './assets/images/avatar/avatar23.jpg'
import avatar24 from './assets/images/avatar/avatar24.jpg'
import avatar25 from './assets/images/avatar/avatar25.jpg'
import avatar26 from './assets/images/avatar/avatar26.jpg'
import avatar27 from './assets/images/avatar/avatar27.jpg'
import avatar28 from './assets/images/avatar/avatar28.jpg'
import avatar29 from './assets/images/avatar/avatar29.jpg'

export const navLinks = [
    {
        id: 1,
        link: 'Games',
        weight: 'bold'
    },
    {
        id: 2,
        link: 'Promotions',
    },
    {
        id: 3,
        link: 'Leaderboards',
    },
    {
        id: 4,
        link: 'Casino Credits'
    },
    {
        id: 5,
        link: 'VIP'
    }
]

export const promotions = [
    {
        id: 1,
        image: p1
    },
    {
        id: 2,
        image: p2
    },
    {
        id: 3,
        image: p3
    },
    {
        id: 4,
        image: p4
    },
    {
        id: 5,
        image: p5
    },
    {
        id: 6,
        image: p6
    },
    {
        id: 7,
        image: p7
    },
    {
        id: 8,
        image: p8
    },
    {
        id: 9,
        image: p9
    },
    {
        id: 10,
        image: p10
    },

]

export const gamesCategories = [
    {
        id: 1,
        icon: <IoDiamond size={30} color='000' />,
        name: 'All Games'
    },
    {
        id: 2,
        icon: <MdOutlineNewLabel size={30} color='000' />,
        name: 'New Games'
    },
    {
        id: 3,
        icon: <GiMoneyStack size={30} color='000' />,
        name: 'Jackpots'
    },
    {
        id: 4,
        icon: <TbHexagonNumber7 size={30} color='000' />,
        name: 'Slots'
    },
    {
        id: 5,
        icon: <GiCardJackDiamonds size={30} color='000' />,
        name: 'Live Dealer'
    },
    {
        id: 6,
        icon: <GiClubs size={30} color='000' />,
        name: 'BlackJack'
    },
    {
        id: 7,
        icon: <img src={roulette} width={30} height={30} alt="roulette" />,
        name: 'Roulette'
    },
    {
        id: 8,
        icon: <GiSpades size={30} color='000' />,
        name: 'Video Poker'
    },
    {
        id: 9,
        icon: <BsFillDice6Fill size={30} color='000' />,
        name: 'Baccarat'
    }
]

export const games = [
    {
        name: 'Alchemistress',
        image: game1,
        catId: 2
    },
    {
        name: 'Beer Barrel Bash',
        image: game2,
        catId: 2
    },
    {
        name: 'Bushido Bounty',
        image: game3,
        catId: 2
    },
    {
        name: 'Champions of Olympus',
        image: game4,
        catId: 2
    },
    {
        name: 'Crazy Jokers',
        image: game5,
        catId: 2
    },
    {
        name: 'Ferris Wheel Fortunes',
        image: game6,
        catId: 2
    },
    {
        name: 'Golden Nile',
        image: game7,
        catId: 2
    },
    {
        name: 'Max Megaways 2',
        image: game8,
        catId: 2
    },
    {
        name: 'Pile Em Up',
        image: game9,
        catId: 2
    },
    {
        name: 'Quick Hit Blitz Blue',
        image: game10,
        catId: 2
    },
    {
        name: 'Twin Turbos',
        image: game11,
        catId: 2
    },
    {
        name: 'Vegas Ways',
        image: game12,
        catId: 2
    },
    {
        name: 'Cosmic Convoy',
        image: game13,
        catId: 2
    },
    {
        name: 'Interstellar Attack',
        image: game14,
        catId: 2
    },
    {
        name: 'Potion Payday',
        image: game15,
        catId: 2
    },
    {
        name: 'Vegas Stacks',
        image: game16,
        catId: 2
    },
    {
        name: 'Single Hand Blackjack',
        image: jackpot1,
        catId: 3
    },
    {
        name: '11 Coins of Fire',
        image: jackpot2,
        catId: 3
    },
    {
        name: '12 Western Horseshoes',
        image: jackpot3,
        catId: 3
    },
    {
        name: '6 Tokens of Gold',
        image: jackpot4,
        catId: 3
    },
    {
        name: '88 Fortunes',
        image: jackpot6,
        catId: 3
    },
    {
        name: 'Action Dragons',
        image: jackpot7,
        catId: 3
    },
    {
        name: 'Action Jack',
        image: jackpot8,
        catId: 3
    },
    {
        name: 'Black Diamond',
        image: jackpot9,
        catId: 3
    },
    {
        name: 'Blackjack 10',
        image: jackpot10,
        catId: 3
    },
    {
        name: 'Blackjack 6 in 1',
        image: jackpot11,
        catId: 3
    },
    {
        name: 'Blue Ribbon',
        image: jackpot12,
        catId: 3
    },
    {
        name: 'Cash Machine',
        image: jackpot13,
        catId: 3
    },
    {
        name: 'Diamond Hearts',
        image: jackpot14,
        catId: 3
    },
    {
        name: 'Double Cash Spinner',
        image: jackpot15,
        catId: 3
    },
    {
        name: 'Double Jackpot',
        image: jackpot16,
        catId: 3
    },
    {
        name: 'Double Jackpot Bullseye',
        image: jackpot17,
        catId: 3
    },
    {
        name: 'Emerald Dream',
        image: jackpot18,
        catId: 3
    },
    {
        name: 'Gold Standard Jackpots',
        image: jackpot19,
        catId: 3
    },
    {
        name: '10k Wonders 10K Ways',
        image: slots1,
        catId: 4
    },
    {
        name: '10,001 Nights',
        image: slots2,
        catId: 4
    },
    {
        name: '11 Champions',
        image: slots3,
        catId: 4
    },
    {
        name: '12 Super Hot Diamonds',
        image: slots4,
        catId: 4
    },
    {
        name: '300 Shields Extreme',
        image: slots5,
        catId: 4
    },
    {
        name: '5 Treasures',
        image: slots6,
        catId: 4
    },
    {
        name: '777 Strike',
        image: slots7,
        catId: 4
    },
    {
        name: '777 Surge',
        image: slots8,
        catId: 4
    },
    {
        name: '7s Fire Power Mix',
        image: slots9,
        catId: 4
    },
    {
        name: '7s Wild',
        image: slots10,
        catId: 4
    },
    {
        name: '6 Wild Sharks',
        image: slots11,
        catId: 4
    },
    {
        name: '9 Loaded Pots',
        image: slots12,
        catId: 4
    },
    {
        name: 'Baccarat Dragon Bonus',
        image: live1,
        catId: 5
    },
    {
        name: 'Blackjack 1',
        image: live2,
        catId: 5
    },
    {
        name: 'Blackjack 2',
        image: live3,
        catId: 5
    },
    {
        name: 'Casino Hold em',
        image: live4,
        catId: 5
    },
    {
        name: 'Craps',
        image: live5,
        catId: 5
    },
    {
        name: 'Free Bet Blackjack',
        image: live6,
        catId: 5
    },
    {
        name: 'Infinite Blackjack',
        image: live7,
        catId: 5
    },
    {
        name: 'Roulette 1',
        image: live8,
        catId: 5
    },
    {
        name: 'Three Card Poker',
        image: live9,
        catId: 5
    },
    {
        name: 'Speed Blackjack',
        image: live10,
        catId: 5,
    },
    {
        name: 'Bet The Set 21',
        image: blackjack1,
        catId: 6,
    },
    {
        name: 'Blackjack(Multi-Hand)',
        image: blackjack2,
        catId: 6,
    },
    {
        name: 'Blackjack-Multihand',
        image: blackjack3,
        catId: 6,
    },
    {
        name: 'Blackjack-Single Hand',
        image: blackjack4,
        catId: 6,
    },
    {
        name: 'Blackjack Blazing 7s',
        image: blackjack5,
        catId: 6,
    },
    {
        name: 'Blackjack Poker & Pairs',
        image: blackjack6,
        catId: 6,
    },
    {
        name: 'Blackjack Xchange',
        image: blackjack7,
        catId: 6,
    },
    {
        name: 'Blackjack with Surrender',
        image: blackjack8,
        catId: 6,
    },
    {
        name: 'Super Fun 21 Extreme',
        image: blackjack9,
        catId: 6,
    },
    {
        name: 'Zappit Blackjack',
        image: blackjack10,
        catId: 6,
    },
    {
        name: '100/1 Roulette',
        image: roulette1,
        catId: 7,
    },
    {
        name: 'Blazing 7s Roulette',
        image: roulette2,
        catId: 7,
    },
    {
        name: 'Double Ball Roulette',
        image: roulette3,
        catId: 7,
    },
    {
        name: 'Double Bonus Roulette',
        image: roulette4,
        catId: 7,
    },
    {
        name: 'First Person L Roulette',
        image: roulette5,
        catId: 7,
    },
    {
        name: 'First Person Roulette',
        image: roulette6,
        catId: 7,
    },
    {
        name: 'Monopoly Big Spin',
        image: roulette7,
        catId: 7,
    },
    {
        name: 'Real Roulette with Sarati',
        image: roulette8,
        catId: 7,
    },
    {
        name: 'Roulette 6',
        image: roulette9,
        catId: 7,
    },
    {
        name: 'Roulette!',
        image: roulette10,
        catId: 7,
    },
    {
        name: 'Space Invaders Roulette',
        image: roulette11,
        catId: 7,
    },
    {
        name: 'Five Play Draw Poker',
        image: video1,
        catId: 8,
    },
    {
        name: 'Game King Video Poker',
        image: video2,
        catId: 8,
    },
    {
        name: 'Jacks Video Poker',
        image: video3,
        catId: 8,
    },
    {
        name: 'Ten Play Draw Poker',
        image: video4,
        catId: 8,
    },
    {
        name: 'Triple Play Draw Poker',
        image: video5,
        catId: 8,
    },
    {
        name: 'Ultimate X Poker Five',
        image: video6,
        catId: 8,
    },
    {
        name: 'Ultimate X Poker Ten',
        image: video7,
        catId: 8,
    },
    {
        name: 'Baccarrat',
        image: baccarrat1,
        catId: 9,
    },
    {
        name: 'Person Dragon Tiger',
        image: baccarrat2,
        catId: 9,
    },
]

export const about = [
    {
        id: 1,
        title: 'Games'
    },
    {
        id: 2,
        title: 'Promotions'
    },
    {
        id: 3,
        title: 'Leaderboards'
    },
    {
        id: 4,
        title: 'Casino Credits'
    },
    {
        id: 5,
        title: 'VIP'
    },
];

export const legal = [
    {
        id: 1,
        title: 'Privacy Policy'
    },
    {
        id: 2,
        title: 'Terms of Service'
    },
    {
        id: 3,
        title: 'Terms of Service'
    },
    {
        id: 4,
        title: 'AML/CTF Policy'
    },
    {
        id: 5,
        title: 'Information Requests'
    },
];

export const help = [
    {
        id: 1,
        title: 'Support'
    },
    {
        id: 2,
        title: 'Complaints'
    },
    {
        id: 3,
        title: 'FAQs'
    },
    {
        id: 4,
        title: 'Fees'
    },
    {
        id: 5,
        title: 'Bug Bounty'
    }
]

export const reviews = [
    {
        name: "John Smith",
        avatar: avatar1,
        description: "Great online casino with a wide variety of games. I love the user-friendly interface and the quick payouts."
    },
    {
        name: "Emily Johnson",
        avatar: avatar2,
        description: "I've been playing on this casino for a while now, and it's been a fantastic experience. The bonuses are generous."
    },
    {
        name: "David Brown",
        avatar: avatar3,
        description: "Smooth gameplay and excellent customer support. I recommend this casino to all my friends."
    },
    {
        name: "Sarah White",
        avatar: avatar4,
        description: "I hit a big win on this casino, and the withdrawal process was hassle-free. It's my go-to online casino."
    },
    {
        name: "Michael Davis",
        avatar: avatar5,
        description: "The live dealer games are top-notch. It feels like playing in a real casino from the comfort of my home."
    },
    {
        name: "Sophia Wilson",
        avatar: avatar6,
        description: "This casino has a fantastic loyalty program. I've received amazing rewards and cashback offers."
    },
    {
        name: "Robert Lee",
        avatar: avatar7,
        description: "The mobile app is responsive and lets me play on my smartphone. I've won big on the slots!"
    },
    {
        name: "Olivia Hall",
        avatar: avatar8,
        description: "As a beginner, I found the tutorials and guides very helpful. I quickly learned how to play and win."
    },
    {
        name: "William Turner",
        avatar: avatar9,
        description: "The customer support team is available 24/7 and has been helpful in resolving my queries."
    },
    {
        name: "Ava Smith",
        avatar: avatar10,
        description: "I love the themed slot games. The graphics are amazing, and the bonus rounds are exciting."
    },
    {
        name: "Ethan Johnson",
        avatar: avatar11,
        description: "Quick and secure deposits and withdrawals. It's crucial for me to have a reliable casino."
    },
    {
        name: "Mia Brown",
        avatar: avatar12,
        description: "I appreciate the responsible gaming options that help me stay within my limits."
    },
    {
        name: "James Wilson",
        avatar: avatar13,
        description: "I've tried many online casinos, but this one stands out for its game selection and promotions."
    },
    {
        name: "Emma Davis",
        avatar: avatar14,
        description: "The progressive jackpots are tempting. I dream of hitting a massive jackpot here!"
    },
    {
        name: "Noah White",
        avatar: avatar15,
        description: "I've had a fantastic time playing with my friends on this casino. We even won together!"
    },
    {
        name: "Isabella Lee",
        avatar: avatar16,
        description: "The user interface is intuitive, making it easy to navigate and find my favorite games."
    },
    {
        name: "Lucas Hall",
        avatar: avatar17,
        description: "This casino has weekly tournaments that add excitement to my gaming experience."
    },
    {
        name: "Avery Martin",
        avatar: avatar18,
        description: "The VIP program offers exclusive benefits and surprises. It's been a VIP treatment for me."
    },
    {
        name: "Mila Turner",
        avatar: avatar19,
        description: "I've tried the sports betting section as well. It's a one-stop destination for my gambling interests."
    },
    {
        name: "Jackson Wilson",
        avatar: avatar20,
        description: "I won a jackpot here, and the casino processed the payment quickly. It's trustworthy and fair."
    },
    {
        name: "Harper Smith",
        avatar: avatar21,
        description: "The customer feedback is valued. They make continuous improvements based on player suggestions."
    },
    {
        name: "Evelyn Johnson",
        avatar: avatar22,
        description: "The casino organizes exciting events and promotions regularly. It keeps me engaged."
    },
    {
        name: "Liam Davis",
        avatar: avatar23,
        description: "I've had a positive experience with this casino's community. The chat rooms are friendly and fun."
    },
    {
        name: "Abigail White",
        avatar: avatar24,
        description: "The variety of payment options makes it convenient for players worldwide."
    },
    {
        name: "Henry Brown",
        avatar: avatar25,
        description: "I've had great success with the table games. The odds are fair, and the dealers are professional."
    },
    {
        name: "Ella Smith",
        avatar: avatar26,
        description: "This casino's website is easy to use and loads quickly. It's perfect for a smooth gaming experience."
    },
    {
        name: "Benjamin Wilson",
        avatar: avatar27,
        description: "The mobile app is fantastic! I can play my favorite casino games on the go, and the graphics are impressive."
    },
    {
        name: "Samuel Turner",
        avatar: avatar28,
        description: "This casino has a great VIP program. I've enjoyed exclusive rewards and personalized service as a loyal player."
    },
    {
        name: "Isabella Martin",
        avatar: avatar29,
        description: "I appreciate the responsible gambling features that help me stay in control of my spending. Highly recommended."
    },
];

