
import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import Marquee from "react-fast-marquee"
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import SignupFields from './signupfields'
import './styles.scss'

function Signup() {
    const [errormessage, setErrorMessage] = useState('')
    return (
        <>
            <Col xl={12} lg={12} md={12} xs={12} className="SignupWrapper">

                <div className='SignupContainer'>
                    <Marquee className='signUpMarquee'>
                        Sign up now and get $2000 AUD free credits
                    </Marquee>

                    <div className='SignupArrowBack'>
                        <Link to="/" style={{ color: 'black', textDecoration: 'none' }}>
                            <BsFillArrowLeftCircleFill size={25} style={{ color: '#0d3a2d' }} />
                        </Link>
                    </div>
                    <div className='SignupContainerChildOne'>

                        <h1 className='mb-4'>Sign up</h1>
                        {
                            errormessage &&
                            <p style={{ color: 'green' }}>{errormessage}</p>
                        }
                        <SignupFields setErrorMessage={setErrorMessage} />
                        <p className='SideSigninSignup mt-3'>Already have an account.
                            {" "}
                            <Link to="/signin" style={{ color: 'black' }}>Sign in</Link>.{" "}
                        </p>
                    </div>

                </div>
            </Col>
        </>
    )
}

export default Signup