import React from 'react'
import './styles.scss'
import { gamesCategories } from '../../data'

const Categories = ({ setSelectedCategoryId, selectedCategoryId }) => {

    const handleCategoryClick = (categoryId, index) => {
        if (index === 0) {
            setSelectedCategoryId(null); // Show all games
        } else {
            setSelectedCategoryId(categoryId); // Filter games based on the category
        }
    };

    return (
        <div className='categoriesWrapper'>
            {
                gamesCategories?.map((gamesCategory, index) => (
                    <div
                        className={`categoryContainer ${selectedCategoryId === gamesCategory.id ? 'categoryContainerSelected' : ''}`}
                        key={gamesCategory.id}
                        onClick={() => handleCategoryClick(gamesCategory.id, index)}>
                        <span style={{ marginRight: 8 }}>{gamesCategory.icon}</span>
                        <span>{gamesCategory.name}</span>
                    </div>
                ))
            }

        </div>
    )
}

export default Categories