import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { about, gamesCategories, help, legal } from '../../data';
import './styles.scss';

function Footer() {
    const cookie = new Cookies();

    return (
        <div className='footerWrapper'>
            <Container>
                <Row>
                    <Col lg={3} md={3} sm={6} xs={12}>
                        <h5 style={{ margin: '0px 0px 24px 0px', color: 'white' }}>ABOUT</h5>
                        {
                            about?.map((item) => (
                                <Link to={!cookie.get('token') && "/signup"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <p key={item.title} style={{ color: 'white', fontSize: 14, cursor: 'pointer', }}>{item.title}</p>
                                </Link>
                            ))
                        }
                    </Col>
                    <Col lg={2} md={2} sm={6} xs={12} className="legal">
                        <h5 style={{ margin: '0px 0px 24px 0px', color: 'white' }}>LEGAL</h5>
                        {
                            legal?.map((item) => (
                                <Link to={!cookie.get('token') && "/signup"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <p key={item.id} style={{ color: 'white', fontSize: 14, cursor: 'pointer', }}>{item.title}</p>
                                </Link>
                            ))
                        }
                    </Col>
                    <Col lg={2} md={2} sm={6} xs={12} className="help">
                        <h5 style={{ margin: '0px 0px 24px 0px', color: 'white' }}>Categories</h5>
                        {
                            gamesCategories.slice(0, 5)?.map((item) => (
                                <Link to={!cookie.get('token') && "/signup"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <p key={item.id} style={{ color: 'white', fontSize: 14, cursor: 'pointer', }}>{item.name}</p>
                                </Link>

                            ))
                        }
                    </Col>
                    <Col lg={2} md={2} sm={6} xs={12} className="help">
                        <h5 style={{ margin: '0px 0px 24px 0px', color: 'white' }}>HELP</h5>
                        {
                            help?.map((item) => (
                                <Link to={!cookie.get('token') && "/signup"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <p key={item.id} style={{ color: 'white', fontSize: 14, cursor: 'pointer', }}>{item.title}</p>
                                </Link>

                            ))
                        }
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={12} className="help">
                        <h5 style={{ margin: '0px 0px 24px 0px', color: 'white' }}>Categories</h5>
                        {
                            gamesCategories.slice(5, 9)?.map((item) => (
                                <Link to={!cookie.get('token') && "/signup"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <p key={item.id} style={{ color: 'white', fontSize: 14, cursor: 'pointer', }}>{item.name}</p>
                                </Link>

                            ))
                        }
                    </Col>

                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className='FooterCopyRightContainer'>
                            <div style={{ marginTop: 64 }}>
                                <p>© Copy Right-2022 All rights reserved.</p>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default Footer