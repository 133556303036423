import './App.scss';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import HomePage from './pages/homepage';
import PublicLayout from './layout/publicLayout';
import AuthLayout from './layout/authLayout';
import Signin from './pages/signin';
import Signup from './pages/signup';
import React, { useEffect, useState } from 'react';
import CustomModal from './components/modal';

function App() {

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    setModalShow(true)
  }, []);

  return (
    <>
      <Router>
        <CustomModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          setModalShow={setModalShow}
        />
        <Routes>
          <Route path='/' element={<PublicLayout />}>
            <Route path="/" element={<HomePage />} />
          </Route>

          <Route path='/' element={<AuthLayout />}>
            <Route path="signin" element={<Signin />} />
            <Route path="signup" element={<Signup />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
