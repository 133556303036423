import React from 'react'
import { Link } from 'react-router-dom'
import SigninFields from './signinfields'
import './styles.scss'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { Col, Row } from 'react-bootstrap'

function Signin() {
    return (
        <>
            <Row>
                <Col xl={12} lg={12} md={12} xs={12} className="SigninWrapper">
                    <div className='SigninContainer'>
                        <div className='SigninArrowBack'>
                            <Link to="/" style={{ color: 'black', textDecoration: 'none' }}>
                                <BsFillArrowLeftCircleFill size={25} style={{ color: '#0d3a2d' }} />
                            </Link>
                        </div>
                        <div className='SigninContainerChildOne'>
                            <h1>Sign in</h1>

                            <SigninFields />
                            <p className='SideSigninSignup mt-3'>
                                Don't have an account.
                                {" "}
                                <Link to="/signup" style={{ color: 'black' }}>Sign up</Link>.{" "}
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Signin